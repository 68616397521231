<template>
  <v-container>
    Inventory
    <v-row justify="space-around">
      <v-card class="mx-auto my-12" width="100%">
        <v-card-title>
          Inventário HRO
        </v-card-title>
        <v-data-table :headers="headers" :items="inventario" item-key="name" class="elevation-1" :search="search"
          :loading="loading">
          <template v-slot:top>
            <v-row dense class="mx-2">
              <v-col cols="12" md="7" lg="7">
                <v-text-field dense prepend-icon="mdi-magnify" v-model="search" label="Pesquisar" class="mx-4"></v-text-field>
              </v-col>
              <v-col cols="12" md="4" lg="4" xlg="4" class="text-end">
                <v-btn class="mx-1" outlined small @click="exportToExcel"><v-icon class="mx-1">mdi-export</v-icon>Exportar para Excel</v-btn>
              
                <v-btn class="mx-1" outlined small @click="get_atendimentos()"><v-icon class="mx-1">mdi-refresh</v-icon>ATUALIZAR</v-btn>
              </v-col>
              <v-col cols="12" md="1" lg="1" xlg="1" class="text-center">Total {{ inventario.length }}</v-col>
            </v-row>
          </template>
          <template v-slot:[`item.storages`]="{ item }">
            <p v-for="(v, k) in item.storages" :key="`id-${k}`">
              {{ v.tamanho }} GB {{ v.modelo }} {{ v.tipo }}
            </p>
          </template>
          <template v-slot:[`item.data`]="{ item }">
            {{ new Date(item.data).toLocaleDateString() }}
            {{ new Date(item.data).toLocaleTimeString() }}
          </template>
          <template v-slot:[`item.editar`]="{ item }">
            <Edit :current-item="item" />
          </template> 
        </v-data-table>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import api from '@/http'
import * as XLSX from 'xlsx'

export default {
  name: "Inventory",
  components:{
    Edit:()=>import('./Edit.vue')
  },
  data: () => ({
    inventario: [],
    search: '',
    loading: false,
    headers: [
      { text: '#', align: 'start', sortable: true, value: 'id' },
      { text: 'ip', align: 'start', sortable: true, value: 'ip_add' },
      { text: 'Hostname', align: 'start', sortable: true, value: 'hostname' },
      { text: 'S.O.', align: 'start', sortable: true, value: 'so' },
      { text: 'Processador', align: 'start', sortable: true, value: 'processador' },
      { text: 'Mem Ram GB', align: 'end', sortable: true, value: 'ram' },
      { text: 'Placa mãe', align: 'start', sortable: true, value: 'placa_mae' },
      { text: 'Disco', align: 'start', sortable: true, value: 'storages' },
      { text: 'Usuário', align: 'start', sortable: true, value: 'usuario' },
      { text: 'Data', align: 'start', sortable: true, value: 'data' },
      { text: 'Empresa', align: 'start', sortable: true, value: 'empresa.nome' },
      { text: 'Unidade', align: 'start', sortable: true, value: 'unidade.nome' },
      { text: 'Jornada', align: 'start', sortable: true, value: 'local.jornada.nome' },
      { text: 'Setor', align: 'start', sortable: true, value: 'setor.nome' },
      { text: 'Local', align: 'start', sortable: true, value: 'local.nome' },
      { text: 'Editar', align: 'start', sortable: true, value: 'editar' }
    ]
  }),
  methods: {
    get_atendimentos() {
      this.loading = true
      api.get('inventario/maquinas-list/')
        .then(res => {
          this.inventario = res.data
        })
        .finally(() => {
          this.loading = false
        })
    },
    exportToExcel() {
      const ws = XLSX.utils.json_to_sheet(this.inventario)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'Inventário')
      XLSX.writeFile(wb, 'inventario.xlsx')
    }
  },
  mounted() {
    this.get_atendimentos()
  }
}
</script>
